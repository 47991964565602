<template>
  <edit-item-component endpoint="unwows" type="unwow"/>
</template>

<script>
export default {
  name: "Edit"
}
</script>

<style scoped>

</style>